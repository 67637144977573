/* eslint-disable react/no-unescaped-entities */
import React, { useState } from 'react';
import { useFormik } from 'formik';
import * as yup from 'yup';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Link from '@mui/material/Link';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert'; // For Lorem v5+
import {v4 as uuid} from "uuid"; 

const validationSchema = yup.object({
  fullName: yup
    .string()
    .trim()
    .min(2, 'Please enter a valid name')
    .max(50, 'Please enter a valid name')
    .required('Please specify your full name'),
  // lastName: yup
  //   .string()
  //   .trim()
  //   .min(2, 'Please enter a valid name')
  //   .max(50, 'Please enter a valid name')
  //   .required('Please specify your last name'),
  email: yup
    .string()
    .trim()
    .email('Please enter a valid email address')
    .required('Email is required.'),
  message: yup
    .string()
    .required('Please enter a message')
});

// password: yup
// .string()
// .required('Please specify your password')
// .min(8, 'The password should have at minimum length of 8'),

const Form = () => {
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarSeverity, setSnackbarSeverity] = useState('success');

  const initialValues = {
    fullName: '',
    email: '',
    message: '',
  };

  const onSubmit = async (values) => {
    const payload = {
      full_name: values.fullName,
      email: values.email,
      message: values.message,
      password: uuid()
    };

    try {
      const response = await fetch('https://cms.cognijiwa.ai/items/users', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(payload),
      });

      if (!response.ok) {
        throw new Error('Network response was not ok');
      }

      if (response.ok) {
        // Handle success
        setSnackbarMessage('Signup successful!');
        setSnackbarSeverity('success');
        setOpenSnackbar(true);
        // Additional success handling...
        formik.resetForm(); // Clear the form
        // After a successful form submission, wait for 2 seconds before redirecting
        setTimeout(() => {
          window.location.href = '/';
        }, 2000); // Delay the redirection by 2000 milliseconds (2 seconds)
      }
    } catch (error) {
      // Handle error
      setSnackbarMessage('Signup failed: ' + error.message);
      setSnackbarSeverity('error');
      setOpenSnackbar(true);
      console.error('Submission error:', error);
    }
  };

  const handleCloseSnackbar = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpenSnackbar(false);
  };

  const formik = useFormik({
    initialValues,
    validationSchema: validationSchema,
    onSubmit,
  });

  return (
    <Box>
      <Box marginBottom={4}>
        <Typography
          sx={{
            textTransform: 'uppercase',
            fontWeight: 'medium',
          }}
          gutterBottom
          color={'text.secondary'}
        >
          Get started
        </Typography>
        <Typography
          variant="h4"
          sx={{
            fontWeight: 700,
          }}
        >
          Talk to us today
        </Typography>
        <Typography color="text.secondary">
        Get your companion to enhance your sales and customer experience today. Contact us now!
        </Typography>
      </Box>
      <form onSubmit={formik.handleSubmit}>
        <Grid container spacing={4}>
          <Grid item xs={12}>
            <Typography variant={'subtitle2'} sx={{ marginBottom: 2 }}>
              Enter your first name
            </Typography>
            <TextField
              label="Your full name *"
              variant="outlined"
              name={'fullName'}
              fullWidth
              value={formik.values.fullName}
              onChange={formik.handleChange}
              error={
                formik.touched.fullName && Boolean(formik.errors.fullName)
              }
              helperText={formik.touched.fullName && formik.errors.fullName}
            />
          </Grid>
          {/* <Grid item xs={12} sm={6}>
            <Typography variant={'subtitle2'} sx={{ marginBottom: 2 }}>
              Enter your last name
            </Typography>
            <TextField
              label="Last name *"
              variant="outlined"
              name={'lastName'}
              fullWidth
              value={formik.values.lastName}
              onChange={formik.handleChange}
              error={formik.touched.lastName && Boolean(formik.errors.lastName)}
              helperText={formik.touched.lastName && formik.errors.lastName}
            />
          </Grid> */}
          <Grid item xs={12}>
            <Typography variant={'subtitle2'} sx={{ marginBottom: 2 }}>
              Enter your email
            </Typography>
            <TextField
              label="Your e-mail address *"
              variant="outlined"
              name={'email'}
              fullWidth
              value={formik.values.email}
              onChange={formik.handleChange}
              error={formik.touched.email && Boolean(formik.errors.email)}
              helperText={formik.touched.email && formik.errors.email}
            />
          </Grid>
          <Grid item xs={12}>
            <Typography variant={'subtitle2'} sx={{ marginBottom: 2 }}>
              Message
            </Typography>
            <TextField
              label="Your question about our services *"
              variant="outlined"
              name={'message'}
              // type={'password'}
              fullWidth
              value={formik.values.message}
              onChange={formik.handleChange}
              error={formik.touched.message && Boolean(formik.errors.message)}
              helperText={formik.touched.message && formik.errors.message}
            />
          </Grid>
          <Grid item container xs={12}>
            <Box
              display="flex"
              flexDirection={{ xs: 'column', sm: 'row' }}
              alignItems={{ xs: 'stretched', sm: 'center' }}
              justifyContent={'space-between'}
              width={1}
              maxWidth={600}
              margin={'0 auto'}
            >
              <Box marginBottom={{ xs: 1, sm: 0 }}>
                {/* <Typography variant={'subtitle2'}>
                  Already have an account?{' '}
                  <Link
                    component={'a'}
                    color={'primary'}
                    href={'/signin'}
                    underline={'none'}
                  >
                    Login.
                  </Link>
                </Typography> */}
              </Box>
              <Button size={'large'} variant={'contained'} type={'submit'}>
              Send the question
              </Button>
            </Box>
          </Grid>
          <Grid
            item
            container
            xs={12}
            justifyContent={'center'}
            alignItems={'center'}
          >
            <Typography
              variant={'subtitle2'}
              color={'text.secondary'}
              align={'center'}
            >
              By clicking "Get started" button you agree with our{' '}
              <Link
                component={'a'}
                color={'primary'}
                href={'/company-terms'}
                underline={'none'}
              >
                company terms and conditions.
              </Link>
            </Typography>
          </Grid>
        </Grid>
      </form>

      <Snackbar open={openSnackbar} autoHideDuration={6000} onClose={handleCloseSnackbar}>
        <Alert onClose={handleCloseSnackbar} severity={snackbarSeverity} sx={{ width: '100%' }}>
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </Box>
  );
};

export default Form;
